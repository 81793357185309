import { useEffect, useRef, useState } from "react";
import { GenericComponent } from "../../components/GenericComponent";
import { Consumidores } from "../../models/Consumidores";
import { useForm } from "react-hook-form";
import { InputComponent } from "../../components/InputComponent";
import { ConsumidorService } from "../../service/ConsumidorService";
import { Toast } from "primereact/toast";
import { Card } from "primereact/card";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Formulario } from "./Formulario";
import { useToken } from "../../components/useToken";
import { FiltroMultiempresa } from "../../components/FiltroMultiempresa";
import { RadioButton } from "primereact/radiobutton";
import { EmpresaService } from "../../service/EmpresaService";
import { Generico } from "../../components/Generico";

export const Consumidor = () => {
    const generic = new GenericComponent();
    const omodel = new Consumidores();
    const { accionesTbl } = Generico();
    const [permss, setPermss] = useState(null);
    const defaultValues = generic.default(omodel.model, null);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues } = useForm({ defaultValues });
    let loadLazyTimeout = null;
    const toast = useRef(null);
    const [clientes, setClientes] = useState(null);
    const [catalogo, setCatalogo] = useState(null);
    const [cliente, setCliente] = useState(null);
    const [esRuc, setEsRuc] = useState(false);
    const [esCedula, setEsCedula] = useState(false);
    const [dialogFrm, setDialogFrm] = useState(false);
    const [empresa, setEmpresa] = useState(null);
    const [filtroTipo, setFiltroTipo] = useState(1);
    const [listaEmpresa, setListaEmpresa] = useState(null);
    const { getRol } = useToken();
    const [dialogConsumidor, setDialogConsumidor] = useState(false);
    const [titleTipo, setTitleTipo] = useState(null);
    const consumidorService = new ConsumidorService();
    const [selectedEmp, setSelectedEmp] = useState(null);
    const [emp, setEmp] = useState(null);
    const empresaService = new EmpresaService();
    const { table, setLoading, setTotalRecords, lazyParams, inDrop, inText, inAutocomplete } = InputComponent({ registers: clientes, omodel, generic, errors, control, permss, id: getValues('id') });

    useEffect(() => {
        loadLazyData();
    }, [lazyParams]);

    const loadLazyData = (tipoConsumidor = null) => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }
        loadLazyTimeout = setTimeout(() => {
            let _lazyParams = lazyParams;
            delete _lazyParams.rtipo;
            delete _lazyParams.empresa;
            if (empresa) {
                _lazyParams.empresa = empresa;
            }
            if (getRol()?.id) {
                _lazyParams.rol = getRol();
            }
            if (permss == null) {
                _lazyParams.rtipo = true;
            }
            if (catalogo?.tipo_formulario && catalogo.tipo_formulario === 'Proveedor-Cliente') {
                _lazyParams.tipoConsumidor = tipoConsumidor ?? filtroTipo;
            } else if (catalogo?.tipo_formulario && catalogo.tipo_formulario !== 'Proveedor-Cliente') {
                _lazyParams.tipoConsumidor = catalogo.tipo_formulario === 'Cliente' ? 1 : 2;
            }
            consumidorService.getConsumidor({ lazyEvent: JSON.stringify(_lazyParams) }).then(res => {
                setTotalRecords(res.data.total);
                setClientes(res.data.registros);
                setLoading(false);
                if (res.data.perss !== undefined) {
                    setPermss(res.data.perss);
                    setCatalogo(res.data.catalogo);
                }
                if (res.data.empresas) {
                    setListaEmpresa(res.data.empresas);
                }
            });
        });
    }

    const openNew = () => {
        let inx = filtroTipo;
        if (catalogo?.tipo_formulario !== 'Proveedor-Cliente') {
            inx = catalogo.tipo_formulario === 'Cliente' ? 1 : 2;
        }
        setCliente(null);
        setTitleTipo(omodel.tipoConsumidor[inx - 1].name);
        setDialogConsumidor(true);
        reset(defaultValues);
    }

    const onSubmit = (data) => {
        if (catalogo?.tipo_formulario !== 'Proveedor-Cliente') {
            data.tipo = catalogo.tipo_formulario === 'Cliente' ? 1 : 2;
        } else {
            data.tipo = filtroTipo;
        }
        data.rol = getRol() ?? null;
        if (getValues('id') == null) {
            consumidorService.newConsumidor(data).then(res => {
                generic.funcSuccess(res, toast);
                setDialogConsumidor(false);
                loadLazyData();
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
        else {
            consumidorService.editarConsumidor(data).then(res => {
                generic.funcSuccess(res, toast);
                setDialogConsumidor(false);
                loadLazyData();
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
    }

    const editConsumidor = () => {
        consumidorService.consultarConsumidor(cliente.id, catalogo.nem).then(res => {
            let _cons = res.data.consumidor;
            const lista = { tipo_identificacion: catalogo.tipo };
            if (listaEmpresa?.length > 0 && _cons.empresa && catalogo.nem !== 'prerol') {
                lista.empresa = listaEmpresa;
            }
            if (catalogo.nem !== 'prerol') {
                setEmp(_cons.empresa);
            }

            Object.keys(lista).forEach(function (key) {
                if (_cons[key] != null) {
                    lista[key].forEach(element => {
                        if (element.cat_id === _cons[key]) {
                            _cons[key] = element;
                        }
                    });
                }
            });
            setEsRuc(JSON.stringify(_cons.tipo_identificacion) === JSON.stringify(catalogo.ruc));
            setEsCedula(JSON.stringify(_cons.tipo_identificacion) === JSON.stringify(catalogo.cedula));
            reset(generic.renewValues(_cons, omodel.model));
            let inx = filtroTipo;
            if (catalogo?.tipo_formulario !== 'Proveedor-Cliente') {
                inx = catalogo.tipo_formulario === 'Cliente' ? 1 : 2;
            }
            setTitleTipo(omodel.tipoConsumidor[inx - 1].name)
            setDialogConsumidor(true);
        });
    }

    const confirmDelete = () => {
        confirmDialog({
            message: '¿Está seguro de eliminar la información?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => eliminarConsumidor()
        });
    }

    const eliminarConsumidor = () => {
        consumidorService.eliminarConsumidor(cliente?.id).then(res => {
            loadLazyData();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcDeleteError(error, toast);
        });
    }

    const onChangeTipo = (e) => {
        setEsRuc(JSON.stringify(e.value) === JSON.stringify(catalogo.ruc));
        setEsCedula(JSON.stringify(e.value) === JSON.stringify(catalogo.cedula));
    }

    const formularios = (daa) => {
        if (catalogo?.tipo_formulario !== 'Proveedor-Cliente') {
            setFiltroTipo(catalogo.tipo_formulario === 'Cliente' ? 1 : 2);
        }
        setDialogFrm(true);
    }

    const filtro = () => {
        return (
            <div className="flex flex-wrap gap-3">
                {omodel.tipoConsumidor.map((item) => {
                    return (
                        <div key={item.id} className="flex align-items-center">
                            <RadioButton inputId={item.id} name="filtroTipo" value={item.id} onChange={(e) => cambioTipo(e.value)} checked={filtroTipo === item.id} />
                            <label htmlFor={item.id} className="ml-2">{item.name}</label>
                        </div>
                    );
                })}
            </div>
        )
    }

    const cambioTipo = (value) => {
        setFiltroTipo(value);
        loadLazyData(value);
    }

    const searchEmp = (event) => {
        setTimeout(() => {
            if (!event.query.trim().length) {
                setEmp([...emp]);
            }
            else {
                empresaService.consultarEmpresaNombre(event.query.toLowerCase()).then(data => {
                    setEmp(data);
                });
            }
        });
    }

    const itemsMenu = () => {
        const items = [];
        if (permss?.actualizar)
            items.push({ label: 'Editar', icon: 'pi pi-pencil', command: () => { editConsumidor() } });
        if (permss?.consultar)
            items.push({ label: 'Generación de notificaciones', icon: 'pi pi-at', command: () => { formularios() } });
        if (permss?.eliminar)
            items.push({ label: 'Eliminar', icon: 'pi pi-trash', command: () => { confirmDelete() } });

        return [{ label: 'Opciones', items: items }];
    }

    return (
        <>
            <ConfirmDialog />
            <Toast ref={toast} />
            {getRol() && getRol()?.ideg && <FiltroMultiempresa loadLazyData={loadLazyData} setEmpresa={setEmpresa} />}
            <Card style={{ maxWidth: '100%', margin: 'auto' }}>
                <Toolbar className="mb-4" start={generic.leftToolbarTemplate(openNew, 'Nuevo', permss)}
                    end={generic.rightToolbarTemplate(clientes, omodel, 'Listado', 'LISTADO')}></Toolbar>
                {catalogo?.tipo_formulario === 'Proveedor-Cliente' && filtro()}
                <br />
                {table({ action01: accionesTbl, reg: setCliente, items: itemsMenu() })}

            </Card>

            <Dialog visible={dialogConsumidor} header={(getValues('id') == null ? "Nuevo " : "Editar ") + titleTipo} modal className="p-fluid modal-ssize"
                onHide={e => setDialogConsumidor(false)} style={{ maxWidth: '800px' }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="formgrid grid">
                        {inDrop('tipo_identificacion', catalogo?.tipo, { lab: omodel.optionLabel, chn: onChangeTipo }, 6)}
                        {esCedula && inText('identificacion', generic.infoIdentificacion('cedula'), 6)}
                        {!esCedula && !esRuc && inText('identificacion', {}, 6)}
                        {esRuc && inText('identificacion', generic.infoIdentificacion('ruc'), 6)}
                        {inText('nombre', {}, 6)}
                        {inText('email', { pattern: omodel.pattern }, 6)}
                        {inText('email2', { pattern: omodel.pattern, obl: 'no' }, 6)}
                        {listaEmpresa?.length > 0 && inDrop('empresa', listaEmpresa, { lab: omodel.optionLabel }, 6)}
                        {(!listaEmpresa?.length || listaEmpresa?.length <= 0) && catalogo?.nem === 'prerol' &&
                            inAutocomplete('empresa', selectedEmp, setSelectedEmp, emp, searchEmp, { lab: omodel.optionLabel, noEsActividad: true, col: 12 })}
                    </div>

                    {generic.buttonsForms(setDialogConsumidor)}
                </form>
            </Dialog>

            <Dialog visible={dialogFrm} header={"Envío de formularios a: " + cliente?.nombre} modal className="p-fluid modal-ssize" onHide={e => setDialogFrm(false)}
                style={{ maxWidth: '1000px' }}>
                <Formulario toast={toast} generic={generic} id={cliente?.id} permss={permss} empresa={empresa} tipo={filtroTipo} />
            </Dialog>
        </>
    )
}
