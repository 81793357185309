import React, { useState, useEffect } from 'react';
import { classNames } from 'primereact/utils';
import { Route } from 'react-router-dom';

import AppTopbar from './AppTopbar';
import AppFooter from './AppFooter';
import AppBreadcrumb from './AppBreadcrumb';
import Dashboard from './components/Dashboard';
import OrigenLicito from './pages/ficha/OrigenLicito';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import { Empresa } from './pages/administracion/Empresa';
import { PersonaJuridica } from './pages/ficha/PersonaJuridica';
import { Accionista } from './pages/ficha/Accionista';
import { ClientePerNa } from './pages/ficha/ClientePerNa';
import { ClientePerJu } from './pages/ficha/ClientePerJu';
import { Empleados } from './pages/ficha/Empleados';
import InfoProveedor from './pages/ficha/InfoProveedor';
import { Menu } from './pages/administracion/Menu';
import { Permiso } from './pages/administracion/Permiso';
import { Rol } from './pages/administracion/Rol';
import { Usuario } from './pages/administracion/Usuario';
import { useToken } from './components/useToken';
import { Parametro } from './pages/administracion/Parametro';
import { Consumidor } from './pages/form/Consumidor';
import { Matriz } from './pages/ficha/factor/Matriz';
import { Institucion } from './pages/administracion/Institucion';
import { Consulta } from './pages/reporte/Consulta';
import { Cambiar } from './pages/administracion/Cambiar';
import { Masivo } from './pages/reporte/Masivo';
import { Historico } from './pages/administracion/Historico';
import { Origenes } from './pages/fuente/Origenes';
import { PersonaFuente } from './pages/fuente/PersonaFuente';
import { DetalleFuente } from './pages/fuente/DetalleFuente';
import { GrupoEmpresa } from './pages/administracion/GrupoEmpresa';
import { Dialog } from 'primereact/dialog';
import { RolEmpresaLista } from './pages/administracion/RolEmpresaLista';
import { Accordion } from 'primereact/accordion';
import { AccordionTab } from 'primereact/accordion';
import { RolEmpresaGrupoLista } from './pages/administracion/RolEmpresaGrupoLista';
import { GestionApp } from './pages/administracion/GestionApp';
import { useHistory } from 'react-router-dom';
import { Peps } from './pages/administracion/Peps';
import { EtlPep } from './pages/administracion/EtlPep';
import { FiltroPep } from './pages/administracion/FiltroPep';
import { ClienteRepPn } from './pages/reporte/ClienteRepPn';
import { ClienteRepPj } from './pages/reporte/ClienteRepPj';
import { ProveedorRep } from './pages/reporte/ProveedorRep';
import { Plantillas } from './pages/matriz/Plantillas';
import { Factores } from './pages/matriz/Factores';
import { PerfilAccionista } from './pages/matriz/PerfilAccionista';
import { AccionistaJuridico } from './pages/matriz/AccionistaJuridico';
import { PerfilEmpleado } from './pages/matriz/PerfilEmpleado';
import { InfoClientes } from './pages/matriz/InfoClientes';
import { PersonaNatural } from './pages/ficha/cpn/PersonaNatural';
import { PepFicha } from './pages/ficha/PepFicha';
import { ConfigArchivo } from './pages/administracion/ConfigArchivo';
import { PersonaJuridicaBVG } from './pages/ficha/pj/PersonaJuridicaBVG';
import { Funcionario } from './pages/ficha/fun/Funcionario';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { AccionistaPnBvg } from './pages/ficha/fun/AccionistaPnBvg';
import { MatrizProveedor } from './pages/matriz/MatrizProveedor';
import { ClienteRepPnBVG } from './pages/reporte/ClienteRepPnBVG';

const App = () => {

    const [menuActive, setMenuActive] = useState(false);
    const [menuMode, ] = useState('static');
    const [colorScheme, ] = useState('light');
    const [menuTheme, ] = useState('layout-sidebar-darkgray');
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
    const [searchActive, setSearchActive] = useState(false);
    const [topbarUserMenuActive, setTopbarUserMenuActive] = useState(false);
    const [topbarNotificationMenuActive, setTopbarNotificationMenuActive] = useState(false);
    const [configActive, setConfigActive] = useState(false);
    const [inputStyle, ] = useState('outlined');
    const [ripple, ] = useState(false);
    const { getMenus, getNumRoles, getNumRolesAd } = useToken();
    const [dialogRol, setDialogRol] = useState(false);

    let menuClick = false;
    let searchClick = false;
    let userMenuClick = false;
    let notificationMenuClick = false;
    let configClick = false;
    const history = useHistory();

    const menu = () => {
        const mns = getMenus();
        let menus = [{ label: 'Inicio', icon: 'pi pi-home', to: '/' }];
        if (mns) {
            mns.forEach(elt => {
                if (!elt.padre) {
                    let item = {};
                    item.label = elt.nombre;
                    item.icon = 'pi pi-fw ' + elt.icono;
                    let items = [];
                    mns.forEach(itm => {
                        if (elt.id === itm.padre) {
                            let itms = {};
                            itms.label = itm.nombre;
                            itms.icon = 'pi pi-fw ' + itm.icono;
                            itms.to = itm.url;
                            itms.command = () => { history.push(itm.url) }
                            items.push(itms);
                        }
                    })
                    if (items.length > 0) {
                        item.items = items;
                    }
                    menus.push(item);
                }
            })
        }
        return menus;
    }

    const routers = [
        { path: '/', component: Dashboard, exact: true, meta: { breadcrumb: [{ parent: 'Dashboard', label: 'Inicio' }] } },
        { path: '/ficha/origenLicito', component: OrigenLicito, meta: { breadcrumb: [{ parent: 'Pages', label: 'Origen Lícito' }] } },
        { path: '/ficha/PersonaJuridica', component: PersonaJuridica, meta: { breadcrumb: [{ parent: 'Pages', label: 'Accionista Persona Jurídica' }] } },
        { path: '/ficha/Accionista', component: Accionista, meta: { breadcrumb: [{ parent: 'Pages', label: 'Accionista Persona Natural' }] } },
        { path: '/ficha/ClientePerNa', component: ClientePerNa, meta: { breadcrumb: [{ parent: 'Pages', label: 'Cliente Persona Natural' }] } },
        { path: '/ficha/ClientePerJu', component: ClientePerJu, meta: { breadcrumb: [{ parent: 'Pages', label: 'Cliente Persona Jurídica' }] } },
        { path: '/ficha/empleados', component: Empleados, meta: { breadcrumb: [{ parent: 'Pages', label: 'Empleado' }] } },
        { path: '/ficha/infoProveedor', component: InfoProveedor, meta: { breadcrumb: [{ parent: 'Pages', label: 'Proveedor' }] } },
        { path: '/administracion/menu', component: Menu, meta: { breadcrumb: [{ parent: 'Pages', label: 'Menús' }] } },
        { path: '/administracion/permiso', component: Permiso, meta: { breadcrumb: [{ parent: 'Pages', label: 'Permisos' }] } },
        { path: '/administracion/rol', component: Rol, meta: { breadcrumb: [{ parent: 'Pages', label: 'Roles' }] } },
        { path: '/administracion/empresa', component: Empresa, meta: { breadcrumb: [{ parent: 'Pages', label: 'Empresa' }] } },
        { path: '/administracion/grupoEmpresa', component: GrupoEmpresa, meta: { breadcrumb: [{ parent: 'Pages', label: 'Grupo Empresa' }] } },
        { path: '/administracion/usuario', component: Usuario, meta: { breadcrumb: [{ parent: 'Pages', label: 'Usuario' }] } },
        { path: '/administracion/gestionApp', component: GestionApp, meta: { breadcrumb: [{ parent: 'Pages', label: 'Gestión aplicación' }] } },
        { path: '/administracion/parametro', component: Parametro, meta: { breadcrumb: [{ parent: 'Pages', label: 'Gestión factores de riesgos' }] } },
        { path: '/administracion/institucion', component: Institucion, meta: { breadcrumb: [{ parent: 'Pages', label: 'Gestión institución Pep' }] } },
        { path: '/administracion/peps', component: Peps, meta: { breadcrumb: [{ parent: 'Pages', label: 'Gestión Peps' }] } },
        { path: '/administracion/etlPep', component: EtlPep, meta: { breadcrumb: [{ parent: 'Pages', label: 'Carga Peps' }] } },
        { path: '/administracion/filtroPep', component: FiltroPep, meta: { breadcrumb: [{ parent: 'Pages', label: 'Filtro Peps' }] } },
        { path: '/form/consumidor', component: Consumidor, meta: { breadcrumb: [{ parent: 'Pages', label: 'Envío de links de formularios' }] } },
        { path: '/factor/matriz', component: Matriz, meta: { breadcrumb: [{ parent: 'Pages', label: 'MATRIZ DE PERFIL DE RIESGOS DEL CLIENTE' }] } },
        { path: '/reporte/consulta', component: Consulta, meta: { breadcrumb: [{ parent: 'Pages', label: 'Consulta - Base de Datos' }] } },
        { path: '/administracion/cambiar', component: Cambiar, meta: { breadcrumb: [{ parent: 'Pages', label: 'Cambiar Contraseña' }] } },
        { path: '/administracion/historico', component: Historico, meta: { breadcrumb: [{ parent: 'Pages', label: 'Historial consultas' }] } },
        { path: '/fuente/origenes', component: Origenes, meta: { breadcrumb: [{ parent: 'Pages', label: 'Orígenes de Fuentes' }] } },
        { path: '/fuente/personaFuente', component: PersonaFuente, meta: { breadcrumb: [{ parent: 'Pages', label: 'Personas vinculadas - ' }] } },
        { path: '/fuente/detalleFuente', component: DetalleFuente, meta: { breadcrumb: [{ parent: 'Pages', label: 'Detalle de Orígenes y Personas vinculadas - ' }] } },
        { path: '/reporte/masivo', component: Masivo, meta: { breadcrumb: [{ parent: 'Pages', label: 'Consulta Masiva' }] } },
        { path: '/reporte/clienteRepPn', component: ClienteRepPn, meta: { breadcrumb: [{ parent: 'Pages', label: 'Reporte Formulario Cliente Persona Natural' }] } },
        { path: '/reporte/clienteRepPnBVG', component: ClienteRepPnBVG, meta: { breadcrumb: [{ parent: 'Pages', label: 'Reporte Persona Natural: Cliente o Proveedor' }] } },
        { path: '/reporte/clienteRepPj', component: ClienteRepPj, meta: { breadcrumb: [{ parent: 'Pages', label: 'Reporte Formulario Cliente Persona Jurídica' }] } },
        { path: '/reporte/proveedorRep', component: ProveedorRep, meta: { breadcrumb: [{ parent: 'Pages', label: 'Reporte Formulario Proveedor' }] } },
        { path: '/matriz/plantillas', component: Plantillas, meta: { breadcrumb: [{ parent: 'Pages', label: 'Generación de plantillas para matrices' }] } },
        { path: '/matriz/factores', component: Factores, meta: { breadcrumb: [{ parent: 'Pages', label: 'Generación de plantillas para matrices' }] } },
        { path: '/matriz/perfilAccionista', component: PerfilAccionista, meta: { breadcrumb: [{ parent: 'Pages', label: 'MATRIZ PERFIL DE RIESGO DEL ACCIONISTA PERSONA NATURAL' }] } },
        { path: '/matriz/accionistaJuridico', component: AccionistaJuridico, meta: { breadcrumb: [{ parent: 'Pages', label: 'MATRIZ DE PERFIL DE RIESGOS DEL ACCIONISTA PERSONA JURÍDICA' }] } },
        { path: '/matriz/perfilEmpleado', component: PerfilEmpleado, meta: { breadcrumb: [{ parent: 'Pages', label: 'MATRIZ PERFIL DEL EMPLEADO' }] } },
        { path: '/matriz/infoCliente', component: InfoClientes, meta: { breadcrumb: [{ parent: 'Pages', label: 'MATRIZ PERFIL DEL CLIENTE' }] } },
        { path: '/matriz/infoProveedor', component: MatrizProveedor, meta: { breadcrumb: [{ parent: 'Pages', label: 'MATRIZ PERFIL DEL PROVEEDOR' }] } },
        { path: '/ficha/personaNatural', component: PersonaNatural, meta: { breadcrumb: [{ parent: 'Pages', label: 'Declaración de Origen y Destino Lícito de Recursos - PERSONA NATURAL, Cliente - Proveedor' }] } },
        { path: '/ficha/personaJuridicaBVG', component: PersonaJuridicaBVG, meta: { breadcrumb: [{ parent: 'Pages', label: 'Declaración de Origen y Destino Lícito de Recursos - Política "Conozca Cliente, Proveedor, Accionista"' }] } },
        { path: '/ficha/pepFicha', component: PepFicha, meta: { breadcrumb: [{ parent: 'Pages', label: 'Personas Expuestas Política y Públicamente' }] } },
        { path: '/ficha/confArchivo', component: ConfigArchivo, meta: { breadcrumb: [{ parent: 'Pages', label: 'Configuración archivos de formularios' }] } },
        { path: '/ficha/funcionario', component: Funcionario, meta: { breadcrumb: [{ parent: 'Pages', label: 'FORMULARIO POLÍTICA "CONOZCA A SU EMPLEADO"' }] } },
        { path: '/ficha/accionistaPnBvg', component: AccionistaPnBvg, meta: { breadcrumb: [{ parent: 'Pages', label: 'FORMULARIO POLÍTICA "CONOZCA A SU ACCIONISTA O DIRECTOR"' }] } },
    ];

    useEffect(() => {
        if (staticMenuMobileActive) {
            blockBodyScroll();
        }
        else {
            unblockBodyScroll();
        }
    }, [staticMenuMobileActive]);

    useEffect(() => {
        changeStyleSheetUrl('layout-css', 'layout-' + colorScheme + '.css', 1);
        changeStyleSheetUrl('theme-css', 'theme-' + colorScheme + '.css', 1);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const changeStyleSheetUrl = (id, value, from) => {
        const element = document.getElementById(id);
        const urlTokens = element.getAttribute('href').split('/');

        if (from === 1) {
            // which function invoked this function
            urlTokens[urlTokens.length - 1] = value;
        } else if (from === 2) {
            // which function invoked this function
            if (value !== null) {
                urlTokens[urlTokens.length - 2] = value;
            }
        } else if (from === 3) {
            // which function invoked this function
            urlTokens[urlTokens.length - 2] = value;
        }

        const newURL = urlTokens.join('/');

        replaceLink(element, newURL);
    };

    const replaceLink = (linkElement, href) => {
        if (isIE()) {
            linkElement.setAttribute("href", href);
        }
        else {
            const id = linkElement.getAttribute("id");
            const cloneLinkElement = linkElement.cloneNode(true);

            cloneLinkElement.setAttribute("href", href);
            cloneLinkElement.setAttribute("id", id + "-clone");

            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            cloneLinkElement.addEventListener("load", () => {
                linkElement.remove();
                cloneLinkElement.setAttribute("id", id);
            });
        }
    };


    const isIE = () => {
        return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
    };

    const onDocumentClick = () => {
        if (!searchClick && searchActive) {
            onSearchHide();
        }

        if (!userMenuClick) {
            setTopbarUserMenuActive(false);
        }

        if (!notificationMenuClick) {
            setTopbarNotificationMenuActive(false);
        }

        if (!menuClick) {
            if (isSlim() || isHorizontal()) {
                setMenuActive(false);
            }

            if (overlayMenuActive || staticMenuMobileActive) {
                hideOverlayMenu();
            }

            unblockBodyScroll();
        }

        if (configActive && !configClick) {
            setConfigActive(false);
        }

        searchClick = false;
        configClick = false;
        userMenuClick = false;
        notificationMenuClick = false;
        menuClick = false;
    };

    const onMenuClick = () => {
        menuClick = true;
    };

    const onMenuButtonClick = (event) => {
        menuClick = true;
        setTopbarUserMenuActive(false);
        setTopbarNotificationMenuActive(false);

        if (isOverlay()) {
            setOverlayMenuActive(prevOverlayMenuActive => !prevOverlayMenuActive);
        }

        if (isDesktop()) {
            setStaticMenuDesktopInactive(prevStaticMenuDesktopInactive => !prevStaticMenuDesktopInactive);
        }
        else {
            setStaticMenuMobileActive(prevStaticMenuMobileActive => !prevStaticMenuMobileActive);
        }

        event.preventDefault();
    };

    const onMenuitemClick = (event) => {
        if (!event.item.items) {
            hideOverlayMenu();

            if (isSlim() || isHorizontal()) {
                setMenuActive(false);
            }
        }
    };

    const onRootMenuitemClick = () => {
        setMenuActive(prevMenuActive => !prevMenuActive);
    };

    const onTopbarUserMenuButtonClick = (event) => {
        userMenuClick = true;
        setTopbarUserMenuActive(prevTopbarUserMenuActive => !prevTopbarUserMenuActive);

        hideOverlayMenu();

        event.preventDefault();
    };

    const onTopbarNotificationMenuButtonClick = (event) => {
        notificationMenuClick = true;
        setTopbarNotificationMenuActive(prevTopbarNotificationMenuActive => !prevTopbarNotificationMenuActive);

        hideOverlayMenu();

        event.preventDefault();
    };

    const toggleSearch = () => {
        setSearchActive(prevSearchActive => !prevSearchActive);
        searchClick = true;
    };

    const onSearchHide = () => {
        setSearchActive(false);
        searchClick = false;
    };

    const onRightMenuButtonClick = (event) => {
        hideOverlayMenu();
        event.preventDefault();
    };

    const hideOverlayMenu = () => {
        setOverlayMenuActive(false);
        setStaticMenuMobileActive(false);
        unblockBodyScroll();
    };

    const blockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.add('blocked-scroll');
        }
        else {
            document.body.className += ' blocked-scroll';
        }
    };

    const unblockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        }
        else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' +
                'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    };

    const isSlim = () => {
        return menuMode === "slim";
    };

    const isHorizontal = () => {
        return menuMode === "horizontal";
    };

    const isOverlay = () => {
        return menuMode === "overlay";
    };

    const isDesktop = () => {
        return window.innerWidth > 1091;
    };

    const containerClassName = classNames('layout-wrapper',
        {
            'layout-overlay': menuMode === "overlay",
            'layout-static': menuMode === "static",
            'layout-slim': menuMode === "slim",
            'layout-horizontal': menuMode === "horizontal",
            'layout-sidebar-dim': colorScheme === "dim",
            'layout-sidebar-dark': colorScheme === "dark",
            'layout-overlay-active': overlayMenuActive,
            'layout-mobile-active': staticMenuMobileActive,
            'layout-static-inactive': staticMenuDesktopInactive && menuMode === "static",
            'p-input-filled': inputStyle === "filled",
            'p-ripple-disabled': !ripple,
        },
        colorScheme === 'light' ? menuTheme : '');

    return (
        <>
            <div className={containerClassName} data-theme={colorScheme} onClick={onDocumentClick}>

                <div className="layout-content-wrapper">
                    <AppTopbar routers={routers} topbarNotificationMenuActive={topbarNotificationMenuActive} topbarUserMenuActive={topbarUserMenuActive}
                        onMenuButtonClick={onMenuButtonClick} onSearchClick={toggleSearch} onTopbarNotification={onTopbarNotificationMenuButtonClick}
                        onTopbarUserMenu={onTopbarUserMenuButtonClick} onRightMenuClick={onRightMenuButtonClick} onRightMenuButtonClick={onRightMenuButtonClick}
                        menu={menu()} menuMode={menuMode} menuActive={menuActive} staticMenuMobileActive={staticMenuMobileActive} onMenuClick={onMenuClick}
                        onMenuitemClick={onMenuitemClick} onRootMenuitemClick={onRootMenuitemClick} setDialogRol={setDialogRol}></AppTopbar>

                    <div className="layout-content">
                        <div className="layout-breadcrumb viewname" style={{ textTransform: 'uppercase' }}>
                            <AppBreadcrumb routers={routers} />
                        </div>

                        {
                            routers.map((router, index) => {
                                if (router.exact) {
                                    return <Route key={`router${index}`} path={router.path} exact component={router.component} render={router.render} />
                                }

                                return <Route key={`router${index}`} path={router.path} component={router.component} render={router.render} />
                            })
                        }
                    </div>

                    <AppFooter />
                </div>
            </div>
            <Dialog visible={dialogRol} header="Cambio de Perfiles" modal className="p-fluid modal-ssize" onHide={e => setDialogRol(false)} style={{ maxWidth: '900px' }}>
                <br />
                <ConfirmDialog />
                <Accordion activeIndex={0}>
                    {getNumRoles() > 0 &&
                        <AccordionTab header="Roles asignados a la empresa directamente">
                            <RolEmpresaLista setDialogRol={setDialogRol} />
                        </AccordionTab>
                    }
                    {getNumRolesAd() > 0 &&
                        <AccordionTab header="Roles asignados a una o varias empresas y grupos">
                            <RolEmpresaGrupoLista setDialogRol={setDialogRol} />
                        </AccordionTab>
                    }
                </Accordion>
            </Dialog>
        </>
    );
}

export default App;
