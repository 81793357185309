import { AutoComplete } from 'primereact/autocomplete';
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { RadioButton } from "primereact/radiobutton";
import { classNames } from "primereact/utils";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { Generico } from './Generico';
import { addLocale, locale } from 'primereact/api';
import { ColorPicker } from 'primereact/colorpicker';
import { MultiSelect } from 'primereact/multiselect';
import { Tag } from 'primereact/tag';
import { InputMask } from "primereact/inputmask";

export const InputComponent = ({ registers, omodel, errors, control, permss, id }) => {

    const model = omodel.model;
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(false);
    const { getFormErrorMessage, paramLazy, currentDate, dateFormat } = Generico();
    const [lazyParams, setLazyParams] = useState(paramLazy());
    const [filtros, setFiltros] = useState();

    useEffect(() => {
        setFiltros(valoresModel('match'));
    }, []);

    locale('es');

    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });

    const yearRanges = (opcion) => {
        const yyear = (new Date()).getFullYear();
        let rng = '1900:' + yyear;
        if (opcion == 'hoyDespues') {
            rng = yyear + ":2100";
        } else if (opcion.includes(':')) {
            rng = opcion
        }

        return rng;
    }

    const noEdit = () => {
        return id != null ? !permss?.actualizar : !permss?.crear;
    }

    const onPage = (event) => {
        event.filters = lazyParams.filters;
        setLazyParams(event);
    }

    const onSort = (event) => {
        event.filters = lazyParams.filters;
        setLazyParams(event);
    }

    const onFilter = (event) => {
        event['first'] = 0;
        setLazyParams(event);
    }

    const inDrop = (field, items, option = {}, col = 3) => {
        const obl = option.obl ? "" : "*";
        const ml = option.ml ? option.ml : '';
        const ne = option.ne !== undefined ? option.ne : noEdit();
        const ro = option.rop !== undefined ? option.rop : false;

        return (
            <div className={"field col-" + col + ml}>
                {model[field].header !== '' && <><label htmlFor={field} className={classNames({ 'p-error': errors[field] })}>{model[field].header}{obl}</label><br /></>}
                <Controller name={field} control={control} rules={{ required: model[field].required }} render={({ field, fieldState }) => (
                    <Dropdown id={field.name} value={field.value} autoFocus onChange={(e) => { field.onChange(e.value === undefined ? null : e.value); if (option.chn) { if (option.val) { option.chn(e, option.val); } else { option.chn(e); } } }} options={items}
                        optionLabel={option.lab} placeholder="Seleccione" filter showClear filterBy={option.lab} className={classNames({ 'p-invalid': fieldState.error })}
                        style={{ width: '100%' }} disabled={ne} readOnly={ro} />
                )} />
                {getFormErrorMessage(errors, field)}
            </div>
        )
    }

    const inDropMultiple = (field, items, option = {}, col = 3) => {
        const obl = option.obl ? "" : "*";
        const ml = option.ml ? option.ml : '';
        const ne = option.ne !== undefined ? option.ne : noEdit();
        return (
            <div className={"field col-" + col + ml}>
                {model[field].header !== '' && <><label htmlFor={field} className={classNames({ 'p-error': errors[field] })}>{model[field].header}{obl}</label><br /></>}
                <Controller name={field} control={control} rules={{ required: model[field].required }} render={({ field, fieldState }) => (
                    <MultiSelect id={field.name} value={field.value} onChange={(e) => field.onChange(e.value)} options={items}
                        optionLabel={option.lab} placeholder="Seleccione" filter showClear filterBy={option.lab} className={classNames({ 'p-invalid': fieldState.error })}
                        style={{ width: '100%' }} disabled={ne} display="chip" />
                )} />
                {getFormErrorMessage(errors, field)}
            </div>
        )
    }

    const inText = (field, option = {}, col = 3) => {
        let readOnly = false;
        if (option.readOnly !== undefined) {
            readOnly = option.readOnly;
        }
        let keyFilter = null;
        if (option.keyFilter) {
            keyFilter = option.keyFilter;
        }
        let minValLen = model[field].min;
        let minMesLen = model[field].minLength;
        if (option.minValLen) {
            minValLen = option.minValLen;
            minMesLen = option.minMesLen;
        }
        let maxValLen = model[field].max;
        let maxMesLen = model[field].maxLength;
        if (option.maxValLen) {
            maxValLen = option.maxValLen;
            maxMesLen = option.maxMesLen;
        }
        let rules = {
            required: model[field].required, maxLength: { value: maxValLen, message: maxMesLen },
            minLength: { value: minValLen, message: minMesLen }
        }
        if (option.pattern) {
            rules['pattern'] = option.pattern;
        }
        let obl = "*";
        if (option.obl) {
            obl = "";
        }

        return (
            <div className={"field col-" + col}>
                <label htmlFor={field} className={classNames({ 'p-error': errors[field] })}>{model[field].header}{obl}&nbsp;&nbsp;</label><br />
                <Controller name={field} control={control} rules={rules} render={({ field, fieldState }) => (
                    <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.error })} readOnly={readOnly} keyfilter={keyFilter} maxLength={maxValLen}
                        style={{ width: '100%' }} disabled={noEdit()} />
                )} />
                {getFormErrorMessage(errors, field)}
            </div>
        )
    }

    const inNumber = (field, decimal, curren, opc = {}, col = 3) => {
        const mode = curren ? 'currency' : 'decimal';
        const local = 'en-US';
        const currency = 'USD';
        let disabled = noEdit();
        if (!noEdit()) {
            disabled = opc.disabled ? opc.disabled : false;
        }
        let min = 0;
        if (opc.min) {
            min = opc.min === 'nulo' ? null : opc.min;
        }
        const tooltip = opc.tooltip ?? null;

        return (
            <div className={"field align-right col-" + col}>
                <label htmlFor={field} className={classNames({ 'p-error': errors[field] })}>{model[field].header}*</label><br />
                <Controller name={field} control={control} rules={{ required: model[field].required }} render={({ field, fieldState }) => (
                    <InputNumber id={field.name} value={field.value} autoFocus onChange={(e) => { field.onChange(e.value); if (opc.func) { opc.func(e, opc.vars) } }}
                        min={min} maxFractionDigits={decimal} tooltip={tooltip}
                        className={classNames({ 'p-invalid': fieldState.error })} mode={mode} locale={local} currency={currency} disabled={disabled} style={{ width: '100%' }} />
                )} />
                {getFormErrorMessage(errors, field)}
            </div>
        )
    }

    const inMask = (field, opc = {}, col = 3) => {
        let disabled = noEdit();
        if (!noEdit()) {
            disabled = opc.disabled ? opc.disabled : false;
        }
        const ast = opc?.ast ?? '*;'
        const tooltip = opc.tooltip ?? null;
        const mask = opc?.mask ?? '9999999999999?';
        const pl = opc?.pl ?? '5930409999999';

        return (
            <div className={"field col-" + col}>
                <label htmlFor={field} className={classNames({ 'p-error': errors[field] })}>{model[field].header}{ast}</label><br />
                <Controller name={field} control={control} rules={{ required: model[field].required }} render={({ field, fieldState }) => (
                    <InputMask id={field.name} value={field.value} onChange={(e) => field.onChange(e.value)} mask={mask} placeholder={pl} tooltip={tooltip}
                        className={classNames({ 'p-invalid': fieldState.error })} disabled={disabled} style={{ width: '100%' }} autoClear={false} />
                )} />
                {getFormErrorMessage(errors, field)}
            </div>
        )
    }

    const inInteger = (field, col = 3) => {
        return (
            <div className={"field col-" + col}>
                <label htmlFor={field} className={classNames({ 'p-error': errors[field] })}>{model[field].header}*</label><br />
                <Controller name={field} control={control} rules={{ required: model[field].required }} render={({ field, fieldState }) => (
                    <InputNumber id={field.name} value={field.value} autoFocus onChange={(e) => { field.onChange(e.value) }} min={0} maxFractionDigits={0}
                        className={classNames({ 'p-invalid': fieldState.error })} disabled={noEdit()} />
                )} />
                {getFormErrorMessage(errors, field)}
            </div>
        )
    }

    const inCheck = (field, lbl, option = {}, col = 3) => {

        return (
            <div className={"field col-" + col}>
                <label htmlFor={field} className={classNames({ 'p-error': errors[field] })}>{lbl ? lbl : model[field].header}</label><br />
                <Controller name={field} control={control} render={({ field, fieldState }) => (
                    <Checkbox inputId={field.name} value={field.value} onChange={(e) => { field.onChange(e.checked); if (option.chn) { if (option.val) { option.chn(e, option.val); } else { option.chn(e); } } }} checked={field.value}
                        className={classNames({ 'p-invalid': fieldState.error })} disabled={noEdit()} />
                )} />
                {getFormErrorMessage(errors, field)}
            </div>
        )
    }

    const inArea = (field, rows, cols, col = 3) => {

        return (
            <div className={"field col-" + col}>
                <label htmlFor={field} className={classNames({ 'p-error': errors[field] })}>{model[field].header}*</label><br />
                <Controller name={field} control={control} rules={{ required: model[field].required }} render={({ field, fieldState }) => (
                    <InputTextarea rows={rows} cols={cols} id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.error })} style={{ maxWidth: '100%' }} disabled={noEdit()} />
                )} />
                {getFormErrorMessage(errors, field)}
            </div>
        )
    }

    const inRadio = (field, items, option = {}) => {
        const col = option.col ?? 3;
        const obl = option.obl ?? "*";
        const param = option.param ?? null;
        return (
            <div className={"field col-" + col}>
                <label htmlFor={field} className={classNames({ 'p-error': errors[field] })}>{model[field].header}{obl}</label><br />
                <div className={"flex flex-wrap gap-3"}>
                    <Controller name={field} control={control} rules={{ required: model[field].required }} render={({ field, fieldState }) => (
                        items.map((item) => {
                            return (
                                <div key={item.id} className="flex align-items-center">
                                    <RadioButton inputId={item.id} name={field.name} value={item.id} onChange={(e) => { field.onChange(e.value); if (option.onChange) { option.onChange(e.value, param) } }}
                                        checked={field.value === item.id} className={classNames({ 'p-invalid': fieldState.error })} />
                                    <label htmlFor={item.id} className="ml-2">{item.name}</label>&nbsp;&nbsp;&nbsp;
                                </div>
                            );
                        })
                    )} />
                    {getFormErrorMessage(errors, field)}
                </div>
            </div>
        )
    }

    const inCalendar = (field, option = {}, col = 3) => {

        let obl = "*";
        if (option.obl) {
            obl = "";
        }
        let maxDate = currentDate;
        let minDate = null;
        if (option.min) {
            minDate = option.minNull ? null : currentDate;
            maxDate = null;
        }
        if (option.onChange) {

        }
        let yr = yearRanges('');
        if (option.yr) {
            yr = yearRanges(option.yr);
        }

        return (
            <div className={"field col-" + col}>
                <label htmlFor={field} className={classNames({ 'p-error': errors[field] })}>{model[field].header}{obl}</label><br />
                <Controller name={field} control={control} rules={{ required: model[field].required }} render={({ field, fieldState }) => (
                    <Calendar id={field.name} value={field.value} onChange={(e) => { field.onChange(e.value); if (option.onChange) { option.onChange(e.value); } }}
                        maxDate={maxDate} minDate={minDate} dateFormat={dateFormat} showIcon disabled={noEdit()}
                        className={classNames({ 'p-invalid': fieldState.error })} readOnlyInput />
                )} />
                {getFormErrorMessage(errors, field)}
            </div>
        )
    }

    const inAutocomplete = (field, selected, setSelected, suggs, search, option = {}) => {
        let col = 3;
        if (option.col) {
            col = option.col;
        }
        const actividad = option.noEsActividad ? '' : <><br />Se encuentra cargado un catálogo de actividades económicas para registrar su actividad favor colocar una referencia por ejemplo: ENSEÑANZA,
            y el sistema genera las opciones que existen sobre la actividad declarada.</>;

        return (
            <div className={"field col-" + col}>
                <label htmlFor={field} className={classNames({ 'p-error': errors[field] })}>{model[field].header}*{actividad}</label><br />
                <Controller name={field} control={control} rules={{ required: model[field].required }} render={({ field, fieldState }) => (
                    <AutoComplete id={field.name} value={field.value} autoFocus onChange={(e) => { field.onChange(e.value); setSelected(e.value) }} suggestions={suggs} tooltip={selected?.nombre}
                        completeMethod={e => { if (option.val) { search(e, option.val) } else { search(e) } }} field={option.lab} dropdownAriaLabel="Seleccione" className={classNames({ 'p-invalid': fieldState.error })}
                        tooltipOptions={{ position: 'top' }} inputStyle={{ width: '100%' }} style={{ width: '100%' }} disabled={noEdit()} dropdown dropdownIcon="pi pi-search"
                        placeholder='Ingrese el texto a buscar y seleccione de la lista presentada.' />
                )} />
                {getFormErrorMessage(errors, field)}
            </div>
        )
    }

    const valoresModel = (opcion, opc = {}) => {
        let icolumn = [];
        if (opcion === 'match') {
            icolumn = {};
        }
        Object.keys(model).forEach(info => {
            switch (opcion) {
                case 'table':
                    if (model[info].table) {
                        const sfilterField = model[info].fielterField !== undefined ? model[info].fielterField : "";
                        const sfilterPlaceholder = model[info].filterPlaceholder !== undefined ? model[info].filterPlaceholder : "";
                        let showFilterMatchModes = true;
                        if (model[info].showFilterMatchModes !== undefined) {
                            showFilterMatchModes = model[info].showFilterMatchModes;
                        }
                        let headerStyle = {};
                        if (model[info].headerStyle) {
                            headerStyle = model[info].headerStyle;
                        }
                        let footer = null;
                        if (model[info]?.footer !== undefined && model[info]?.footer !== null) {
                            footer = model[info].footer;
                            if (opc?.footers && opc?.footers[info]) {
                                footer = opc.footers[info];
                            }
                        }
                        if (model[info]?.tag) {
                            icolumn.push(<Column key={info} field={info} header={model[info].header} sortable={model[info].sort} filter={model[info].filter} fielterField={sfilterField}
                                filterPlaceholder={sfilterPlaceholder} showFilterMatchModes={showFilterMatchModes} headerStyle={headerStyle} footer={footer} body={(rowData) => fieldTemplate(rowData, info)} />);
                        } else {
                            icolumn.push(<Column key={info} field={info} header={model[info].header} sortable={model[info].sort} filter={model[info].filter} fielterField={sfilterField}
                                filterPlaceholder={sfilterPlaceholder} showFilterMatchModes={showFilterMatchModes} headerStyle={headerStyle} footer={footer} />);
                        }
                    }
                    break;
                case 'match':
                    if (model[info].table) {
                        if (model[info].matchMode) {
                            let _item = { value: null, matchMode: model[info].matchMode };
                            if (model[info].operator) {
                                _item = { operator: model[info].operator, constraints: [_item] }
                            }
                            icolumn[info] = _item;
                        }
                    }
                    break;
                default:
                    break;
            }
        });

        return icolumn;
    }

    const fieldTemplate = (data, field) => {
        let inf = data[field];
        if (model[field].tag === true) {
            inf = <>{inf}<br /><Tag value={model[field].mtaq} severity={model[field].severity}></Tag></>;
        } else if (data[field] === model[field]?.tag) {
            inf = <>{inf}<br /><Tag value={model[field].mtaq} severity={model[field].severity}></Tag></>;
        }
        return inf;
    };

    const table = (opc = {}) => {

        let action = valoresModel('table', opc);
        if (opc.action01) {
            action.push(<Column body={(rowData) => opc.action01(rowData, opc.reg, opc.items)} key="action" />);
        }
        if (opc.action0) {
            action.push(<Column body={(rowData) => opc.action0(rowData)} key="action" />);
        }
        if (opc.action) {
            action.push(<Column body={(rowData) => opc.action(rowData, opc.methEdit, opc.perm, opc.methDelete)} key="action" />);
        }
        if (opc.action2) {
            action.push(<Column body={(rowData) => opc.action2(rowData, opc.methEdit, opc.perm, opc.methDelete, opc.add)} key="action" />);
        }
        if (opc.action3) {
            action.push(<Column body={(rowData) => opc.action3(rowData, opc.methEdit, opc.icono, opc.details, opc.perm, opc.methDelete)} key="action" />);
        }
        if (opc.action4) {
            action.push(<Column body={(rowData) => opc.action4(rowData, opc.methEdit, opc.icono, opc.details, opc.perm, opc.methDelete, opc.load, opc.idv)} key="action" />);
        }
        if (opc.action5) {
            action.push(<Column body={(rowData) => opc.action5(rowData, opc.methEdit, opc.icono, opc.details, opc.perm, opc.methDelete, opc.iconExtra, opc.methExtra)} key="action" />);
        }
        return (
            <DataTable value={registers} lazy className="datatable-responsive"
                filterDisplay="row" dataKey="id"
                paginator first={parseInt(lazyParams.first)} rows={parseInt(process.env.REACT_APP_ROWS_NUM)} totalRecords={totalRecords} onPage={onPage}
                onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
                onFilter={onFilter} filters={filtros} loading={loading}>
                {action}
            </DataTable>
        )
    }

    const downloadPdf = (res, fileName) => {
        const fileURL = window.URL.createObjectURL(
            new Blob([res])
        );
        let fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", fileName);
        document.body.appendChild(fileLink);

        fileLink.click();
        document.body.removeChild(fileLink);
    }

    const inNumberBasic = (field, decimal, curren, opc = {}) => {
        const mode = curren ? 'currency' : 'decimal';
        const local = 'en-US';
        const currency = 'USD';
        let disabled = noEdit();
        if (!noEdit()) {
            disabled = opc.disabled ? opc.disabled : false;
        }
        let min = 0;
        if (opc.min) {
            min = opc.min === 'nulo' ? null : opc.min;
        }
        const tooltip = opc.tooltip ?? null;

        return (
            <>
                <Controller name={field} control={control} rules={{ required: model[field].required }} render={({ field, fieldState }) => (
                    <InputNumber id={field.name} value={field.value} autoFocus onChange={(e) => { field.onChange(e.value); if (opc.func) { opc.func(e, opc.vars) } }}
                        min={min} maxFractionDigits={decimal} tooltip={tooltip} inputClassName="border-2 border-blue-500 rounded-md w-full bg-blue-100"
                        className={classNames({ 'p-invalid': fieldState.error })} mode={mode} locale={local} currency={currency} disabled={disabled} />
                )} />
                {getFormErrorMessage(errors, field)}
            </>
        )
    }

    const inColor = (field, col, opc = {}) => {
        let obl = "*";
        if (opc.obl) {
            obl = "";
        }

        return (
            <div className={"field col-" + col}>
                <label htmlFor={field} className={classNames({ 'p-error': errors[field] })}>{model[field].header}{obl}</label><br />
                <Controller name={field} control={control} rules={{ required: model[field].required }} render={({ field, fieldState }) => (
                    <ColorPicker value={field.value} onChange={(e) => { field.onChange(e.value); if (opc.func) { opc.func(e, opc.vars) } }}
                        className={classNames({ 'p-invalid': fieldState.error })} />
                )} />
                {getFormErrorMessage(errors, field)}
            </div>
        )
    }

    return {
        table,
        setLoading,
        setTotalRecords,
        totalRecords,
        lazyParams,
        inDrop,
        inText,
        inRadio,
        inArea,
        inNumber,
        inInteger,
        inCheck,
        inCalendar,
        inAutocomplete,
        downloadPdf,
        inNumberBasic,
        inColor,
        inDropMultiple,
        inMask
    }
}
