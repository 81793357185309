import { Panel } from 'primereact/panel';
import { useForm } from 'react-hook-form';
import { Generico } from '../../../components/Generico';
import { InputComponent } from '../../../components/InputComponent';
import { useEffect, useState } from 'react';
import { Divider } from 'primereact/divider';
import { Financiera } from '../../../models/fun/Financiera';
import { FuncionarioService } from '../../../service/ficha/FuncionarioService';

export const InfFinanciera = ({ id, toast, nextPrev, next, permss }) => {

    const omodel = new Financiera();
    const model = omodel.model;
    const { funcError, funDefault, funcSuccess, renewValues, estadosSiNo } = Generico();
    const defaultValues = funDefault(model);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues, setValue } = useForm({ defaultValues });
    const { inText, inNumber, inDrop } = InputComponent({ omodel, errors, control, permss, id });
    const [siIngresos, setSiIngresos] = useState(true);
    const [siEgresos, setSiEgresos] = useState(true);
    const funSrv = new FuncionarioService();

    useEffect(() => {
        editFicha();
    }, [id]);

    const onSubmit = (data) => {
        data.seccion = 'seccion4';
        data.id = id;
        funSrv.editar(data, 'funcionario').then(res => {
            funcSuccess(res, toast);
            next();
        }).catch(error => {
            funcError(error, toast, setError);
        });
    }

    const editFicha = () => {
        funSrv.consultar(id, 'funcionario', 'seccion4').then(res => {
            let _dec = res.data.fun;
            const lista = {declara_sri: estadosSiNo };
            Object.keys(lista).forEach(function (key) {
                if (_dec[key] != null) {
                    lista[key].forEach(element => {
                        if (element.cat_id === _dec[key]) {
                            _dec[key] = element;
                        }
                    });
                }
            });
            setSiIngresos(parseFloat(_dec.INGR_MENSUAL_OTROS) > 0);
            setSiEgresos(parseFloat(_dec.EGRE_MENSUAL_OTROS) > 0);
            reset(renewValues(_dec, model));
        });
    }

    const actualizarPatrimonio = (e, info) => {
        const ingr = ['INGR_MENSUAL_PROPIOS', 'INGR_MENSUAL_CONYUGUE', 'INGR_MENSUAL_OTROS'];
        const egre = ['EGRE_MENSUAL_ALIMENTACION', 'EGRE_MENSUAL_EDUCACION', 'EGRE_MENSUAL_VIVIENDA', 'EGRE_MENSUAL_SALUD', 'EGRE_MENSUAL_CREDITOS', 'EGRE_MENSUAL_OTROS'];
        let totalIngresos = 0;
        let totalEgresos = 0;
        ingr.forEach(itm => {
            if (getValues(itm) && !isNaN(getValues(itm))) {
                totalIngresos += parseFloat(getValues(itm));
            }
        });
        egre.forEach(itm => {
            if (getValues(itm) && !isNaN(getValues(itm))) {
                totalEgresos += parseFloat(getValues(itm));
            }
        });
        setSiIngresos(parseFloat(getValues('INGR_MENSUAL_OTROS')) > 0);
        setSiEgresos(parseFloat(getValues('EGRE_MENSUAL_OTROS')) > 0);
        setValue('INGR_MENSUAL_TOTAL', totalIngresos);
        setValue('EGRE_MENSUAL_TOTAL', totalEgresos);
    }

    const onError = (errors) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se han detectado errores. Por favor, revise la información ingresada y proceda a corregir', life: 3000 });
    }

    return (
        <form onSubmit={handleSubmit(onSubmit, onError)}>
            <Panel header="5.- Información Financiera: (Deberá incluir la Sociedad Conyugal o entregar respaldos como disolución u otros, de ser aplicable)" className="panel-ficha">
                <div className="formgrid grid">
                    {inText('origen_fondos', {}, 12)}

                    <div className="field col-3">
                        <div className="formgrid grid">
                            <div className="field col-1"><b>b)</b></div>
                            <div className="field col-11"><b>ESTIMADOS INGRESOS MENSUALES:</b></div>
                            {inNumber('INGR_MENSUAL_PROPIOS', 2, true, { func: actualizarPatrimonio, vars: null }, 12)}
                            {inNumber('INGR_MENSUAL_CONYUGUE', 2, true, { func: actualizarPatrimonio, vars: null }, 12)}
                            {inNumber('INGR_MENSUAL_OTROS', 2, true, { func: actualizarPatrimonio, vars: null }, 12)}
                            {inNumber('INGR_MENSUAL_TOTAL', 2, true, { disabled: true }, 12)}
                            <Divider />
                            {inDrop('declara_sri', estadosSiNo, { lab: omodel.optionLabel }, 12)}
                            {siIngresos && inText('otros_ingresos', {}, 12)}
                        </div>
                    </div>
                    <div className="field col-1"></div>

                    <div className="field col-3">
                        <div className="formgrid grid">
                            <div className="field col-12"><b>ESTIMADOS EGRESOS MENSUALES:</b></div>
                            {inNumber('EGRE_MENSUAL_ALIMENTACION', 2, true, { func: actualizarPatrimonio, vars: null }, 12)}
                            {inNumber('EGRE_MENSUAL_EDUCACION', 2, true, { func: actualizarPatrimonio, vars: null }, 12)}
                            {inNumber('EGRE_MENSUAL_VIVIENDA', 2, true, { func: actualizarPatrimonio, vars: null }, 12)}
                            {inNumber('EGRE_MENSUAL_SALUD', 2, true, { func: actualizarPatrimonio, vars: null }, 12)}
                            {inNumber('EGRE_MENSUAL_CREDITOS', 2, true, { func: actualizarPatrimonio, vars: null }, 12)}
                            {inNumber('EGRE_MENSUAL_OTROS', 2, true, { func: actualizarPatrimonio, vars: null }, 12)}
                            {inNumber('EGRE_MENSUAL_TOTAL', 2, true, { disabled: true }, 12)}
                        </div>
                    </div>
                    <div className="field col-1"></div>

                    <div className="field col-3">
                        <div className="formgrid grid">
                        <div className="field col-12"><b>TOTAL RUBROS**:</b></div>
                            <div className="field text-xs col-12"><b>**</b>Ingrese la información de las siguientes secciones para actualizar estos rubros</div>
                            {inNumber('activo', 2, true, { func: actualizarPatrimonio, vars: null, disabled: true }, 12)}
                            {inNumber('pasivo', 2, true, { func: actualizarPatrimonio, vars: null, disabled: true }, 12)}
                            {inNumber('patrimonio', 2, true, { disabled: true, min: 'nulo' }, 12)}
                            <div className="field col-12"><b>Detalle a considerar:</b></div>
                            <div className="field col-3 m-0 border-1">Activos</div>
                            <div className="field col-9 m-0 border-1">{omodel.det1}</div>
                            <div className="field col-3 m-0 border-1">Pasivos</div>
                            <div className="field col-9 m-0 border-1">{omodel.det2}</div>
                            <Divider />
                            {siEgresos && inText('otros_egresos', {}, 12)}
                        </div>
                    </div>
                    {omodel.nota}
                </div>
            </Panel>
            <br />

            {nextPrev()}
        </form>
    )
}
